





































import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
import MediaPlan from '@/models/MediaPlan'

@Component({
  components: {
    SelectPicker,
  },
})
export default class InsertionOrderPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public show_number!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public multiple!: boolean

  @Prop({ default: '' })
  public agency_id!: string

  @Prop({ default: '' })
  public station_id!: string

  @Prop({ default: '' })
  public advertiser_id!: string

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'Order' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  public local_value: string[] | string | null = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public ready: boolean = false

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
        this.ready = true
      } else {
        this.busy = true
        MediaPlan.searchOptions({
          value: this.value,
        }).then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
      }
    } else {
      this.updateLabel()
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      MediaPlan.searchOptions({
        value: this.value,
      }).then(response => {
        this.label_name = response[0].name
        this.ready = true
      })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    MediaPlan.searchOptions({
      search: search.includes('*') ? search : `*${search}*`,
      owner_company_id: this.agency_id,
      client_id: this.advertiser_id,
    }).then(response => {
      this.options = response
      this.busy = false
      this.ready = true
    })
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    if (val == null) {
      this.$emit('input', null)
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        let has_options = false
        if (Array.isArray(this.value)) {
          has_options = true
          this.value.forEach((v: any) => {
            if (!this.options.some((o: any) => o.value === v)) {
              has_options = false
            }
          })
        }
        if (
          (Array.isArray(this.value) && this.value.length > 0 && !has_options)
          || typeof this.value === 'string'
        ) {
          MediaPlan
            .searchOptions({
              value: Array.isArray(this.value) ? this.value : [this.value],
            })
            .then(response => {
              this.options = response
              this.busy = false
              this.local_value = this.value
              this.ready = true
            })
        } else {
          this.local_value = this.value
        }
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('company_id')
  public onChangeCompany() {
    this.searchOptions()
  }

  @Watch('station_id')
  public onChangeStation() {
    this.searchOptions()
  }
}
