











































import { Component, Prop, Watch } from 'vue-property-decorator'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserForm from '@/pages/User/components/UserForm.vue'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    SelectPicker,
    UserForm,
  },
})
export default class UserPicker extends ViewModel {
  @Prop({ default: true })
  public edit!: boolean

  @Prop({ default: false })
  public allow_empty!: boolean

  @Prop()
  public company_id!: string

  @Prop()
  public station_id!: string

  @Prop({ default: 30 })
  public duration!: number

  @Prop({ default: false })
  public vertical!: boolean

  @Prop({ default: false })
  public create_panel!: boolean

  @Prop({ default: 'User' })
  public label!: string

  @Prop({ default: () => null })
  public value!: string | null

  @Prop({ default: false })
  public disabled!: boolean

  public local_value: string[] | string | null = []

  public new_id: string = ''

  public show_sidebar: boolean = false

  public update: boolean = true

  public uuid: string = ''

  public busy: boolean = false

  public ready: boolean = false

  public options: SelectOption[] = []

  public label_name: string = 'N/A'

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
      this.ready = true
    } else {
      this.busy = true
      User.module
        .searchOptions({
          value: this.value,
        })
        .then(response => {
          this.options = response
          this.busy = false
          this.local_value = this.value
          this.ready = true
        })
    }
    this.updateLabel()
  }

  public updateLabel() {
    if (!this.value) {
      this.label_name = 'N/A'
    } else if (!Array.isArray(this.value)) {
      User.module
        .searchOptions({
          value: this.value,
        })
        .then(response => {
          this.label_name = response[0].name
          this.ready = true
        })
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
        owner_id: this.station_id ?? this.company_id,
      })
      .then(response => {
        this.options = response
        this.busy = false
        this.ready = true
      })
  }

  public get can_create() {
    return this.create_panel && this.user.can('user', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.user
  }

  @Watch('local_value')
  public onChangeValue() {
    this.update = false
    this.$emit('input', this.local_value)
  }

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      let has_options = false
      if (Array.isArray(this.value)) {
        has_options = true
        this.value.forEach((v: any) => {
          if (!this.options.some((o: any) => o.value === v)) {
            has_options = false
          }
        })
      }
      if (
        (Array.isArray(this.value) && this.value.length > 0 && !has_options)
        || typeof this.value === 'string'
      ) {
        User.module
          .searchOptions({
            value: Array.isArray(this.value) ? this.value : [this.value],
          })
          .then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
    this.updateLabel()
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      this.local_value = this.panel_callback
      this.layout.clearSidePanelCallback()
    }
  }

  public toggleSideBar() {
    this.layout.showSidePanel({
      name: 'user',
      id: this.uuid,
      data: {
        company_id: this.company_id,
        station_id: this.station_id,
      },
    })
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }
}
