






































import { Component, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'

@Component({})
export default class StatusIndicator extends ViewModel {
  @Prop({ default: '#41B883' })
  public color!: string;
}
