import { render, staticRenderFns } from "./UserForm.vue?vue&type=template&id=35da3a1d&scoped=true&"
import script from "./UserForm.vue?vue&type=script&lang=ts&"
export * from "./UserForm.vue?vue&type=script&lang=ts&"
import style0 from "../User.scss?vue&type=style&index=0&id=35da3a1d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35da3a1d",
  null
  
)

export default component.exports