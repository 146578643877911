/** @format */

import Filter from '@/models/Filter'
import Api from '@/models/Api'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import ReportViewModule from '@/store/model/ReportViewModule'
import PaginateOptions from './interface/PaginateOptions'
import WebMessage from './WebMessage'
import Company from './Company'
import User from './User'

export default class ReportView {
  public id: string | null = null

  public name: string = ''

  public company_id: string | null = ''

  public owner_id: string | null = ''

  public created_at: string = ''

  public updated_at: string = ''

  public filters: Filter = new Filter()

  public static toObject(data: any, cache: boolean = true): ReportView {
    const view = new ReportView()

    view.id = data.id
    view.name = data.name
    view.company_id = data.company_id
    view.owner_id = data.owner_id
    view.created_at = data.created_at
    view.updated_at = data.updated_at

    if (data.company) {
      Company.toObject(data.company)
    }
    if (data.owner) {
      User.toObject(data.owner)
    }
    if (data.filters) {
      view.filters = Filter.toObject(data.filters)
    }

    //  Cache Object List
    if (cache) ReportView.module.update(view)

    return view
  }

  public static toObjectList(data: any, cache: boolean = true): ReportView[] {
    const views = new Array<ReportView>()

    data.forEach((value: any) => {
      const report_view = ReportView.toObject(value, false)
      views.push(report_view)
    })

    //  Cache Object List
    if (cache) ReportView.module.update(views)

    return views
  }

  private _company: Company | null = null

  get company(): Company | null {
    if (
      this.company_id
      && (!this._company || this.company_id != this._company.id)
    ) {
      this._company = Company.find(this.company_id)
    }

    return this._company
  }

  private _owner: User | null = null

  get owner(): User | null {
    if (
      this.owner_id
      && (!this._owner || this.owner_id != this._owner.id)
    ) {
      this._owner = User.find(this.owner_id)
    }

    return this._owner
  }

  /// State Management
  public static get module(): ReportViewModule {
    if (!store.hasModule('report_view')) {
      store.registerModule('report_view', ReportViewModule)
    }

    return getModule(ReportViewModule)
  }

  public static find(id: string): ReportView | null {
    const o = ReportView.module.data.find(report_view => report_view.id === id)
    return o instanceof ReportView ? o : null
  }

  public static filter(filter: string[]): ReportView[] {
    if (Array.isArray(filter)) {
      return ReportView.module.data.filter(
        report_view => report_view.id && filter.includes(report_view.id),
      )
    }
    return ReportView.module.data.filter(filter)
  }

  public static async get(id: string): Promise<ReportView | null> {
    return ReportView.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return ReportView.module.paginate(options)
  }

  public save() {
    const api = new Api()

    if (this.id) {
      return api
        .put(`report_view/${this.id}`, {
          name: this.name,
          company_id: this.company_id,
          owner_id: this.owner_id,
          filters: {
            line_items: this.filters.line_items,
            publishers: this.filters.publishers,
            devices: this.filters.devices,
            account_executives: this.filters.account_executives,
            advertisers: this.filters.advertisers,
            agencies: this.filters.agencies,
            stations: this.filters.stations,
            orders: this.filters.orders,
            billing_contracts: this.filters.billing_contracts,
          },
        })
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .post('report_view', {
        name: this.name,
        company_id: this.company_id,
        owner_id: this.owner_id,
        filters: {
          line_items: this.filters.line_items,
          publishers: this.filters.publishers,
          devices: this.filters.devices,
          account_executives: this.filters.account_executives,
          advertisers: this.filters.advertisers,
          agencies: this.filters.agencies,
          stations: this.filters.stations,
          orders: this.filters.orders,
          billing_contracts: this.filters.billing_contracts,
        },
      })
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`report_view/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSave(response: any) {
    const report_view = ReportView.toObject(response.data.result.report_view)

    WebMessage.success(`View "${report_view.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const report_views = ReportView.filter(response.data.result.deleted)

    let message

    if (report_views.length == 1) {
      message = `View "${report_views[0].name}" deleted!`
    } else {
      message = 'Views deleted!'
    }

    WebMessage.success(message)

    ReportView.module.delete(report_views)

    return response
  }

  private onError(error: any) {
    return error
  }
}
