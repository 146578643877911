





























































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import InsertionOrderPicker from '@/components/InsertionOrderPicker/InsertionOrderPicker.vue'
import InsertionOrderItemPicker from '@/components/InsertionOrderItemPicker/InsertionOrderItemPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import Filter from '@/models/Filter'
import Api from '@/models/Api'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import DatePickerDate from '@/models/DatePickerDate'
import ScheduleItem from './components/ScheduleItem.vue'

@Component({
  components: {
    Widget,
    ScheduleItem,
    IconAction,
    DatePicker,
    CompanyPicker,
    UserPicker,
    InsertionOrderPicker,
    InsertionOrderItemPicker,
  },
})
export default class InventoryDistribution extends ViewModel {
  public filters: Filter = new Filter()

  public loading: boolean = false

  public show_items: boolean = false

  public download_show_items: boolean = false

  public records: any = []

  public modal: any = {
    download: false,
    filters: false,
  }

  public fields(type: string) {
    const fields: any = [
      {
        key: 'name',
        label: type === 'order' ? 'Order' : type === 'creative' ? 'Creative' : 'Line Item',
        sortable: true,
        tdAttr: {
          width: '60%',
        },
      },
      {
        key: 'distribution_live',
        label: 'Live',
        sortable: true,
        thClass: 'text-center',
        tdAttr: {
          width: '20%',
        },
      },
      {
        key: 'distribution_ctv',
        label: 'CTV',
        sortable: true,
        thClass: 'text-center',
        tdAttr: {
          width: '20%',
        },
      },
      {
        key: 'impressions',
        label: 'Impressions',
        sortable: true,
        class: 'text-center',
      },
      {
        key: 'completion_rate',
        label: 'VCR',
        sortable: true,
        class: 'text-center',
      },
      {
        key: 'booked_vcr',
        label: 'Target Completed Views',
        sortable: true,
        class: 'text-center',
      },
    ]

    if (type === 'order') {
      fields.push({
        key: 'details',
        label: 'Details',
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 10%',
      })
    }

    return fields
  }

  public created() {
    const { query } = this.$route

    if (
      query.start
      && query.end
      && typeof query.start === 'string'
      && typeof query.end === 'string'
    ) {
      this.filters.date = new DatePickerDate(query.start, query.end, null, 'YYYY-MM-DD')
    }
    if (query.agencies) this.filters.agencies = this.formatFilter(query.agencies)
    if (query.account_executives) {
      this.filters.account_executives = this.formatFilter(query.account_executives)
    }
    if (query.sales_reps) this.filters.sales_reps = this.formatFilter(query.sales_reps)
    if (query.advertisers) this.filters.advertisers = this.formatFilter(query.advertisers)
    if (query.stations) this.filters.stations = this.formatFilter(query.stations)
    if (query.publishers) this.filters.publishers = this.formatFilter(query.publishers)
    if (query.line_items) this.filters.line_items = this.formatFilter(query.line_items)
    if (query.devices) this.filters.devices = this.formatFilter(query.devices)
    if (query.orders) this.filters.orders = this.formatFilter(query.orders)
    if (query.billing_contracts) {
      this.filters.billing_contracts = this.formatFilter(query.billing_contracts)
    }

    if (query.show_items) {
      this.show_items = query.show_items === 'true' || query.show_items === '1'
    }

    this.run()
  }

  public formatFilter(data: any) {
    if (Array.isArray(data)) {
      return data
    }
    return [data]
  }

  public run(filters: any = {}, cb: any = null) {
    const api = new Api()
    this.loading = true
    api
      .get('diagnostic/distribution', {
        start: this.filters.date.start_date_string,
        end: this.filters.date.end_date_string,
        agencies: this.filters.agencies,
        advertisers: this.filters.advertisers,
        orders: this.filters.orders,
        line_items: this.filters.line_items,
        sales_reps: this.filters.sales_reps,
        show_items: this.show_items,
        ...filters,
      })
      .then(result => {
        if (cb) {
          cb(result.data)
        } else {
          this.records = result.data.map((advertiser: any) => {
            advertiser.expand_items = this.show_items
            advertiser.loading = false
            advertiser.orders = advertiser.orders.map((order: any) => {
              order._showDetails = this.show_items
              order.loading = false
              return order
            })
            return advertiser
          })
        }
        this.loading = false
      })
  }

  public confirmFilter() {
    this.modal.filters = false
    this.run()
  }

  public download() {
    this.modal.download = true
  }

  public confirmDownload() {
    this.modal.download = false
    const api = new Api()
    this.loading = true
    api
      .get('diagnostic/distribution/export', {
        start: this.filters.date.start_date_string,
        end: this.filters.date.end_date_string,
        agencies: this.filters.agencies,
        advertisers: this.filters.advertisers,
        orders: this.filters.orders,
        line_items: this.filters.line_items,
        sales_reps: this.filters.sales_reps,
        show_items: this.download_show_items,
        instance_id: this.instance_id,
      })
      .then(result => {
        WebMessage.success('Generating Export Data, do not close this window!')
        this.loading = false
      })
      .catch(error => {
        this.loading = false
      })
  }

  public expandItems(type: string, row: any = null) {
    let request_data: any = {}
    if (type === 'advertiser') {
      row.expand_items = !row.expand_items
      const empty_items = row.orders.filter((o: any) => o.items.length === 0)

      // Expand items with data
      this.records
        .find((a: any) => a.id === row.id)
        .orders.filter((o: any) => o.items.length !== 0)
        .forEach((o: any) => {
          o._showDetails = row.expand_items
        })

      // Stop process when no missing data is found
      if (empty_items.length === 0) return

      row.loading = true
      // Build Request Data
      request_data = {
        orders: empty_items.map((o: any) => o.order_id),
        get_items: true,
      }
    } else if (type === 'order') {
      // Toggle details if select item already has its items
      if (row.items.length > 0) {
        row._showDetails = !row._showDetails
        return
      }

      row.loading = true
      request_data = {
        orders: [row.order_id],
        get_items: true,
      }
    }

    this.run(request_data, (data: any) => {
      row.loading = false
      if (type === 'advertiser') {
        row.orders.forEach((o: any) => {
          o._showDetails = row.expand_items
          o.items = data[o.order_id].filter((item: any) => item.impressions > 0)
        })
      } else {
        row._showDetails = true
        row.items = data[row.order_id].filter((item: any) => item.impressions > 0)
      }
    })
  }
}
